import type { RouteConfig } from 'vue-router';
import { copyStakeGuard, isUserWallet, isLobbySection } from '@/helpers/copyStakeHelpers';

export default [
  {
    path: '/account',
    component: (): Promise<any> => import(/* webpackMode: "lazy" */ `@/views/AccountView/AccountView.vue`),
    children: [
      {
        path: '',
        name: 'account',
        component: (): Promise<any> => import(/* webpackMode: "lazy" */ `@/views/AccountView/AccountViewInfo.vue`)
      },
      {
        path: '/account/streaming',
        name: 'account-streaming',
        component: (): Promise<any> => import(/* webpackMode: "lazy" */ `@/views/AccountView/AccountViewStreaming.vue`)
      },
      {
        path: '/account/language',
        name: 'account-language',
        component: (): Promise<any> => import(/* webpackMode: "lazy" */ `@/views/AccountView/AccountViewLanguage.vue`)
      }
    ]
  },
  {
    path: '/',
    component: (): Promise<any> => import(/* webpackMode: "lazy" */ `@/views/CopyStakeView/CopyStakeView.vue`),
    children: [
      {
        path: 'broadcast/:id',
        name: 'stream',
        meta: { hideHeader: true },
        component: (): Promise<any> =>
          import(/* webpackMode: "lazy" */ `@/views/CopyStakeView/CopyStakeStreamView.vue`),
        beforeEnter: copyStakeGuard(isUserWallet)
      },
      {
        path: '',
        name: 'lobby',
        component: (): Promise<any> => import(/* webpackMode: "lazy" */ `@/views/CopyStakeView/CopyStakeLobbyView.vue`)
      },
      {
        path: ':type',
        name: 'all',
        component: (): Promise<any> => import(/* webpackMode: "lazy" */ `@/views/CopyStakeView/CopyStakeListView.vue`),
        beforeEnter: copyStakeGuard(isLobbySection)
      }
    ]
  },
  { path: '*', redirect: { path: '/' } }
] as RouteConfig[];
