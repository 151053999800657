import type { LayoutState } from './LayoutState';
import { defaultLocale } from '@/helpers/localeHelpers';
import { isFairspin } from './LayoutState';
import { isDemo } from '@/api/helpers/urlParams';

export default {
  /**
   * Determine active langs base on environment.
   * @param state Account State.
   * @returns active langs.
   */
  langs(state: LayoutState): Array<string> {
    if (state.permittedLangs?.length) {
      return state.permittedLangs;
    }

    return [defaultLocale];
  },

  /**
   * returned active theme
   * @param state layout state
   * @returns active theme
   */

  activeTheme(state: LayoutState): string {
    if (isDemo) return state.theme;

    return `theme-${state.operatorName?.toLowerCase()}`;
  },

  isFairspin(state: LayoutState): boolean {
    return !!isFairspin || state.operatorName?.toLowerCase()?.startsWith('fairspin');
  },

  explorerLink(): string {
    return process.env.VUE_APP_EXPLORER_URL;
  }
};
