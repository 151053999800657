import type { TSubscribe } from '../../helpers/initSubscribes';
import { StreamEventBus } from '@/helpers/copyStakeHelpers';
import { EStreamEventType } from '@/api/schema';

/**
 * Subscribe to event which notifies about the new updates in stream.
 */
export default {
  topic: 'events',
  handler: ({ data }: any): void => {
    StreamEventBus.$emit(EStreamEventType.STREAMER_STATUS_CHANGE, data);
  }
} as TSubscribe;
