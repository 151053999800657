






















import Vue from 'vue';
import { onUnmounted, defineComponent, getCurrentInstance, ref, PropType } from '@vue/composition-api';
import FaqModule from '@/modules/faq/FaqModule.vue';
import { FAQ_CATEGORIES, type FaqCategoriesType } from '@/modules/faq/types';

type FaqModalBeforeOpenEvent = {
  params: {
    category?: FaqCategoriesType;
    topic?: string;
  };
};

export default defineComponent({
  name: 'FaqModal',
  components: {
    FaqModule,
  },
  props: {
    excludedCategories: {
      type: Array as PropType<FaqCategoriesType[]>,
      default: () => [FAQ_CATEGORIES.LOYALTY]
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    const currentInstance = getCurrentInstance();
    const modal = currentInstance.proxy.$modal as Vue['$modal'];

    const el = ref(null);
    const activeCategory = ref<FaqCategoriesType>(FAQ_CATEGORIES.COPY_STAKE);
    const activeTopic = ref<string>('');

    const hideModal = () => {
      modal.hide('faq-modal');
      emit('close');
    };
    const handlerBeforeOpenModal = (event: FaqModalBeforeOpenEvent) => {
      const initCategory = event.params?.category;

      if (initCategory && !Object.values(FAQ_CATEGORIES).includes(initCategory)) {
        console.warn(`[handlerBeforeOpenModal]: ${initCategory} must be one of: ${Object.values(FAQ_CATEGORIES)}`);
      }

      activeCategory.value = initCategory ?? FAQ_CATEGORIES.LOYALTY;
      activeTopic.value = event.params?.topic ?? '';
    };
    const handlerScrollToTop = (): void => {
      if (el.value) {
        el.value.$el.scrollTop = 0;
      }
    };
    const handleCloseModal = () => {
      hideModal();
    };

    onUnmounted(() => {
      hideModal();
    });

    return {
      el,
      handlerBeforeOpenModal,
      handleCloseModal,
      handlerScrollToTop,
      activeCategory,
      activeTopic
    };
  }
});
