















































import StreamConnectInput from '@/components/copy-stake/StreamConnectInput.vue';
import StreamConnectSelect from '@/components/copy-stake/StreamConnectSelect.vue';
import StreamFormControlsBlock from '@/components/copy-stake/StreamFormControlsBlock.vue';

import { TStreamer } from '@/api/schema';

export default {
  name: 'FaqGroupCopyStakeInstruction',
  components: {
    StreamConnectInput,
    StreamConnectSelect,
    StreamFormControlsBlock
  },
  data(): any {
    return {
      streamers: Object.freeze([
        {
          avatar: '/static/images/stream/avatars/2.png',
          nickname: 'anyutka_1014',
          game: 'Double Blazing',
          followersCount: 6,
          playerWalletHash: '123'
        },
        {
          avatar: `/static/images/stream/avatars/1.png`,
          nickname: 'just_ns',
          game: 'Royal fortunator',
          followersCount: 6,
          playerWalletHash: '321'
        }
      ]) as TStreamer[]
    };
  }
};
