import { EPushType, pushMessage } from '@/sockets/helpers/common';

let WORKER: Worker;
const THREAD = 'WORKER';

export const createSocketWorker = (timeout: number): { stop: () => void; start: (cb?: () => void) => void } => {
  const disposeWorker = (): void => {
    WORKER.terminate();
    WORKER = null;
    pushMessage('Worker terminated', EPushType.DEBUG, THREAD);
  };
  const workerTimeoutFn =
    'function() {const ctx = this; const startTime = Date.now(); setInterval(() => ctx.postMessage(Date.now() - startTime), timeout)}';
  const makeWorker = () => {
    if (typeof Worker !== 'undefined') {
      pushMessage('Worker init success', EPushType.DEBUG, THREAD);

      return new Worker(
        URL.createObjectURL(
          new Blob([`(${workerTimeoutFn.replace('timeout', `${timeout}`)})()`], { type: 'text/javascript' })
        ),
        { name: 'copybet,trueplay.io', credentials: 'same-origin' }
      );
    }
  };

  const startWorker = (cb?: () => any): void => {
    if (!WORKER) {
      WORKER = makeWorker();
    }

    if (cb && typeof cb === 'function' && WORKER instanceof Worker) {
      WORKER.onmessage = cb;
    }
  };

  return {
    stop: disposeWorker,
    start: startWorker
  };
};
