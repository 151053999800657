
















































import { defineComponent, ref, toRefs, computed, PropType } from '@vue/composition-api';
import { useI18n } from '@/plugins/i18n';
import useScreen from '@/composables/useScreen';
import FaqNav from '@/modules/faq/components/FaqNav';
import FaqGroupDefault from '@/modules/faq/components/FaqGroupDefault';
import FaqGroupCopyStake from '@/modules/faq/components/FaqGroupCopyStake';
import FaqGroupLoyalty from '@/modules/faq/components/FaqGroupLoyalty';
import FaqTopic from '@/modules/faq/components/FaqTopic';
import FaqTopicMobile from '@/modules/faq/components/FaqTopicMobile';
import CloseIcon from './assets/images/close-icon.svg?inline';
import { FaqCategoriesType, FaqByCategories, FaqGroup, FaqQuestion, FaqTopicNavigation } from '@/modules/faq/types';
import { FAQ_CATEGORIES } from '@/modules/faq/types';

export default defineComponent({
  name: 'FaqModule',
  components: {
    CloseIcon,
    FaqNav,
    FaqGroupDefault,
    FaqGroupCopyStake,
    FaqGroupLoyalty,
    FaqTopic,
    FaqTopicMobile
  },
  props: {
    defaultActiveCategory: {
      type: String as PropType<FaqCategoriesType>,
      required: true
    },
    defaultActiveTopic: {
      type: String,
      default: ''
    },
    excludedCategories: {
      type: Array as PropType<FaqCategoriesType[]>,
      default: () => []
    }
  },
  emits: ['close', 'scrollToTop'],
  setup(props, { emit }) {
    const { isDesktop } = useScreen();
    const i18n = useI18n();
    const { defaultActiveCategory, defaultActiveTopic, excludedCategories } = toRefs(props);

    const activeCategory = ref<FaqCategoriesType>(defaultActiveCategory.value);
    const activeTopic = ref<string>(defaultActiveTopic.value);

    const globalVariables = computed<Record<string, string>>(() => ({}));
    const faqByCategories = computed<FaqByCategories | null>(() => {
      const translateMessageByLocale = i18n.messages[i18n.locale];
      if (!translateMessageByLocale) {
        return null;
      }

      const categories = Object.keys(translateMessageByLocale.faq);

      return categories
        ? (categories.reduce((accum, category: FaqCategoriesType) => {
            if (!excludedCategories.value.includes(category)) {
              accum[category] = translateMessageByLocale.faq[category];
            }

            return accum;
          }, {}) as FaqByCategories)
        : null;
    });
    const activeCategoryInfo = computed<FaqGroup | null>(() => {
      return faqByCategories.value ? faqByCategories.value[activeCategory.value] : null;
    });
    const activeTopicInfo = computed<FaqQuestion | null>(() => {
      if (activeCategory.value && activeTopic.value) {
        return {
          title: i18n.t(`faq.${activeCategory.value}.questions.${activeTopic.value}.title`, globalVariables.value),
          content: i18n.t(`faq.${activeCategory.value}.questions.${activeTopic.value}.content`, globalVariables.value)
        } as FaqQuestion;
      } else {
        return null;
      }
    });
    const activeTopicNavigation = computed<FaqTopicNavigation | null>(() => {
      if (!activeTopic.value || !activeCategoryInfo.value) {
        return null;
      }

      const topicKeys = Object.keys(activeCategoryInfo.value.questions);
      const activeTopicIndex = topicKeys.indexOf(activeTopic.value);
      const prevTopic = activeTopicIndex !== 0 ? topicKeys[activeTopicIndex - 1] : null;
      const nextTopic = activeTopicIndex < topicKeys.length - 1 ? topicKeys[activeTopicIndex + 1] : null;

      return {
        prev: prevTopic,
        next: nextTopic
      };
    });

    const handleOnCategoryChange = (payload: FaqCategoriesType): void => {
      activeCategory.value = payload;
      activeTopic.value = '';
    };
    const handleOnTopicChange = (payload?: string): void => {
      if (!payload) {
        activeTopic.value = '';
        return;
      }

      const isQuestionValid = activeCategoryInfo.value && activeCategoryInfo.value.questions[payload];
      if (!isQuestionValid) {
        return;
      }

      activeTopic.value = payload;

      if (!isDesktop.value) {
        emit('scrollToTop');
      }
    };
    const handleCloseFaqModal = (): void => {
      emit('close');
    };

    return {
      isDesktop,
      activeCategory,
      activeTopic,
      faqByCategories,
      activeCategoryInfo,
      activeTopicInfo,
      activeTopicNavigation,
      handleOnCategoryChange,
      handleOnTopicChange,
      handleCloseFaqModal,
      FAQ_CATEGORIES
    };
  }
});
