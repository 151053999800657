import Vue from 'vue';
import Router from 'vue-router';
import paths from './paths';
import { scrollToTop } from '@/helpers/scrollHelpers';
import { sendRoute } from '@/helpers/postMessageHelpers';

Vue.use(Router);

const router = new Router({
  base: '/',
  mode: 'history',
  routes: paths,

  scrollBehavior(to, _, savedPosition): any {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }

    setTimeout(scrollToTop);

    return { x: 0, y: 0 };
  }
});
router.beforeEach((to, _, next) => {
  sendRoute(to);
  next();
});

export default router;
