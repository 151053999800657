import Hotjar from '@hotjar/browser';

import { PluginFunction, PluginObject } from 'vue';

export interface VueHotjarOptions {
  id: number;
  isProduction?: boolean;
  snippetVersion?: number;
  debug?: boolean;
}

export interface VueHotjarPlugin extends PluginObject<VueHotjarOptions> {
  install: PluginFunction<VueHotjarOptions>;
}

type HotjarCommand = 'event' | 'identify' | 'stateChange';

export interface WindowWithHotjar extends Window {
  hj?: (method: HotjarCommand, ...data: unknown[]) => void;
}

declare const window: WindowWithHotjar;

const validate = (options: VueHotjarOptions): boolean => {
  const id = () => {
    // Expression checkes if the Hotjar Traking Id contains only numbers and no white spaces
    const idExpression = new RegExp(/^([0-9])*\d$/g);
    if (!options.id) {
      throw new Error('Hotjar Tracking ID is not defined');
    } else if (idExpression.test(`${options.id}`) === false) {
      throw new Error('Invalid Hotjar Tracking ID');
    } else if (typeof options.id !== 'number') {
      throw new Error('Hotjar Tracking ID expects a number');
    } else {
      return true;
    }
  };

  const boolean = (key: keyof VueHotjarOptions) => {
    if (typeof options[key] !== 'boolean' && options[key]) {
      throw new Error(`${key} expects a boolean`);
    } else {
      return true;
    }
  };
  const isProduction = () => boolean('isProduction');
  const debug = () => boolean('debug');

  const snippetVersion = () => {
    if (typeof options.snippetVersion !== 'number' && options.snippetVersion) {
      throw new Error('snippetVersion expects a number');
    } else {
      return true;
    }
  };

  return [id(), isProduction(), debug(), snippetVersion()].every(Boolean);
};

const init = (options: VueHotjarOptions) => {
  try {
    const { id, snippetVersion = 6, isProduction = true, debug = false } = options;

    if (validate(options) && isProduction) {
      Hotjar.init(id, snippetVersion, { debug });

      return window.hj;
    } else {
      console.log(
        '%c 🚨 HotJar Tracking Disabled 🚨',
        'color: #fff; background: rgba(255, 167, 38, 0.5); font-size: 14px; border-radius: 4px; padding: 10px 8px; margin: 4px 0;'
      );
      return false;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

const VueHotjarPlugin: VueHotjarPlugin = {
  install(Vue, options) {
    if (Vue.version[0] === '2') {
      Vue.prototype.$hj = init(options);
      Vue.prototype.$hjOptions = options;
    } else {
      init(options);
    }
  }
};

export default VueHotjarPlugin;
