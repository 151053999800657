







import { defineComponent } from '@vue/composition-api';
import FaqGroupDefault from '@/modules/faq/components/FaqGroupDefault';

export default defineComponent({
  name: 'FaqGroupLoyalty',
  components: {
    FaqGroupDefault
  }
});
