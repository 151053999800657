import { token, queryTheme } from '@/api/helpers/urlParams';
import { defaultLocale } from '@/helpers/localeHelpers';
import type { TStreamer } from '@/api/schema';
import { getTokenLanguage, validateToken } from '@/helpers/tokenHelpers';
import { getLocalStorageData } from '@/helpers/localStorageHelpers';
import { parentIsFS } from '@/helpers/urlHelpers';
import type { TDisabledWidgetStatus } from '@/helpers/disabledWidgetStatusHelpers';

export type TSavedPreset = {
  spinRemaining: number;
  percentage: number;
  isChangeBetRule: boolean;
  isChangeGameRule: boolean;
};

/**
 * The initial state of the layout.
 * @category LayoutStore
 */

export const isFairspin = parentIsFS;

const getInitTheme = (): string => {
  if (queryTheme) return queryTheme;

  return 'theme-fairspin';
};

const lang = getTokenLanguage() || defaultLocale;

export default {
  userLogin: null,
  walletHash: null,
  lang,
  permittedLangs: [lang],
  loadedLanguages: [],
  isDemo: false,
  operatorName: null,
  operatorId: null,
  theme: getInitTheme(),
  themeColors: {},
  token: validateToken(token || getLocalStorageData('walletAuthToken')),
  disabledWidgetStatus: null,
  operatorHomePageURL: null,
  streamer: null,
  nickname: null,
  maxBetLimit: 0,
  showOnlyStreamers: false,
  savedPreset: {
    isChangeBetRule: false,
    isChangeGameRule: false,
    percentage: 1,
    spinRemaining: 100
  },
  streamUrl: null
};

/**
 * Describes the state of the layout.
 * @category LayoutStore
 */
export interface LayoutState {
  userLogin: boolean;
  walletHash: string;
  lang: string;
  permittedLangs: string[];
  loadedLanguages: string[];
  isDemo: boolean;
  operatorName: null | string;
  operatorId: null | number | string;
  theme: string;
  themeColors: { [key: string]: string };
  token: string;
  disabledWidgetStatus: TDisabledWidgetStatus;
  operatorHomePageURL: string | null;
  streamer: TStreamer;
  nickname: string;
  maxBetLimit: number;
  showOnlyStreamers: boolean;
  savedPreset: TSavedPreset;
  streamUrl: string;
}
