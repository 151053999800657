










import { defineComponent } from '@vue/composition-api';
import useScreen from '@/composables/useScreen';

export default defineComponent({
  name: 'FaqGroup',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    const { isDesktop } = useScreen();

    return { isDesktop };
  }
});
