import { isSocketsDebugEnabled, token } from '@/api/helpers/urlParams';
import { API_CLIENT_URL } from '@/helpers/urlHelpers';
import { StreamEventBus } from '@/helpers/copyStakeHelpers';
import { Client, messageCallbackType, StompHeaders } from '@stomp/stompjs/esm6';
import { v4 } from 'uuid';

import trim from 'lodash/trim';
import SockJS from 'sockjs-client';
import { EPushType, initReconnectListener, pushMessage } from '@/sockets/helpers/common';
import { initSubscribes } from '@/sockets/helpers/initSubscribes';
import { createSocketWorker } from '@/sockets/helpers/socketWorker';

import { EStreamEventType } from '@/api/schema';
import { isAuthorized } from '@/helpers/tokenHelpers';
import { StompSubscription } from '@stomp/stompjs';

const DYNAMIC_SUBSCRIPTION = new Map<
  string,
  { subscription: StompSubscription; callback: messageCallbackType; destination: string }
>();

const initDynamicSubscribes = (ws: Client) => {
  if (DYNAMIC_SUBSCRIPTION.size === 0) return;

  DYNAMIC_SUBSCRIPTION.forEach(({ destination, callback }, key) => {
    const subscription = ws.subscribe(destination, callback);

    DYNAMIC_SUBSCRIPTION.set(key, { subscription, callback, destination });
  });
};
const debugHandler = (msg: string): void => {
  if (isSocketsDebugEnabled) {
    pushMessage(msg, EPushType.DEBUG);
  }
};

const pushToStream = (): void => {
  StreamEventBus.$emit(EStreamEventType.TIMEOUT, {});
  pushMessage('Stream timeout update', EPushType.WARN, 'STREAM');
};

const pushReconnect = (): void => {
  StreamEventBus.$emit(EStreamEventType.WS_RECONNECT, {});
  pushMessage('Reconnect connection', EPushType.WARN, 'INFO');
};

export const createWSClient = (): {
  client: Client;
  connect: () => void;
  subscribe: (destination: string, callback: messageCallbackType) => StompSubscription;
  unsubscribe: (id: StompSubscription, headers?: StompHeaders) => void;
  disconnect: () => Promise<void>;
} => {
  const socketUrl: string = `${trim(API_CLIENT_URL || '', '/')}/api/v1/copystake/ws?token=${token}`;

  let START_TIME = 0;
  let UPTIME = 0;

  const HEARTBEAT_TIMEOUT = 5000;
  const WORKER_TIMEOUT = HEARTBEAT_TIMEOUT * 2;

  const worker = createSocketWorker(WORKER_TIMEOUT);

  const ws: Client = new Client({
    webSocketFactory: () => new SockJS(socketUrl),
    reconnectDelay: HEARTBEAT_TIMEOUT,
    heartbeatIncoming: HEARTBEAT_TIMEOUT,
    heartbeatOutgoing: HEARTBEAT_TIMEOUT,
    logRawCommunication: isSocketsDebugEnabled,
    debug: debugHandler,
    onConnect: (): void => {
      if (START_TIME) {
        pushReconnect();
      }

      START_TIME = Date.now();
      UPTIME = 0;
      pushMessage('Success connection');
      initDynamicSubscribes(ws);
      initSubscribes(ws);
    },
    onDisconnect: (): void => pushMessage('Disconnect connection', EPushType.WARN, 'INFO'),
    onWebSocketError: async (): Promise<void> => {
      pushMessage('Websocket Error', EPushType.ERROR);
      await reconnect();
    },
    onWebSocketClose: (): void => pushMessage('Close connection', EPushType.WARN, 'INFO')
  });

  // Support for legacy solutions.
  (window as any).__socketClient = ws;

  const disconnect = async (): Promise<void> => {
    try {
      await ws.deactivate({ force: true });
    } catch (e) {
      ws.forceDisconnect();
    }
  };

  const reconnect = (): Promise<void> => disconnect().then(() => ws.activate());

  const backgroundThread = (): void => {
    const currentTimeout = Date.now() - START_TIME - UPTIME;
    UPTIME += currentTimeout;

    if (WORKER_TIMEOUT < currentTimeout - 500) pushToStream();
  };

  if (token) {
    worker.start(backgroundThread);

    initReconnectListener(async (isVisible) => {
      if (isVisible) {
        if (isAuthorized()) {
          ws.activate();
        }
      } else {
        await ws.deactivate({ force: true });
      }
    });
  }

  const addSubscription = (destination: string, callback: messageCallbackType): StompSubscription => {
    const uuid = v4();
    const subscription = ws.subscribe(destination, callback);

    DYNAMIC_SUBSCRIPTION.set(uuid, { subscription, callback, destination });

    return { ...subscription, id: uuid } as StompSubscription;
  };

  const removeSubscription = (subscription: StompSubscription, headers?: StompHeaders): void => {
    const additional = DYNAMIC_SUBSCRIPTION.get(subscription.id);

    if (additional) {
      DYNAMIC_SUBSCRIPTION.delete(subscription.id);
      additional.subscription.unsubscribe(headers);
    } else {
      subscription.unsubscribe(headers);
    }
  };

  return {
    client: ws,
    connect: () => {
      if (isAuthorized()) {
        ws.activate();
      }
    },
    subscribe: addSubscription,
    unsubscribe: removeSubscription,
    disconnect
  };
};
