























import type { VueConstructor } from 'vue';
import { defineComponent, toRefs } from '@vue/composition-api';
import type { PropType } from '@vue/composition-api';
import FaqGroupLoyalty from '@/modules/faq/components/FaqGroupLoyalty';
import FaqGroupCopyStake from '@/modules/faq/components/FaqGroupCopyStake';
import CopyStakeIcon from './assets/images/copy-stake-icon.svg?inline';
import HoldToEarnIcon from './assets/images/hold-to-earn-icon.svg?inline';
import ChevronIcon from '../../assets/images/chevron-icon.svg?inline';
import type { FaqByCategories, FaqCategoriesType } from '@/modules/faq/types';
import useScreen from '@/composables/useScreen';

export default defineComponent({
  name: 'FaqNav',
  components: {
    ChevronIcon
  },
  props: {
    activeCategory: {
      type: String as PropType<FaqCategoriesType>,
      default: ''
    },
    activeTopic: {
      type: String,
      default: ''
    },
    faqByCategories: {
      type: Object as PropType<FaqByCategories>,
      required: true
    }
  },
  emits: ['changeCategory', 'changeTopic'],
  setup(props, { emit }) {
    const { isDesktop } = useScreen();
    const { activeCategory, activeTopic } = toRefs(props);

    const getNavGroupIconByCategory = (category: FaqCategoriesType): VueConstructor => {
      switch (category) {
        case 'copyStake':
          return CopyStakeIcon;
        case 'loyalty':
          return HoldToEarnIcon;
        default:
          return null;
      }
    };
    const getNavGroupInfoByCategory = (category: FaqCategoriesType): VueConstructor => {
      switch (category) {
        case 'copyStake':
          return FaqGroupCopyStake;
        case 'loyalty':
          return FaqGroupLoyalty;
        default:
          return null;
      }
    };
    const handleOnCategoryChange = (category: FaqCategoriesType): void => {
      if (activeCategory.value === category) {
        return;
      }
      emit('changeCategory', category);
    };
    const handleOnTopicChange = (topic: string): void => {
      if (activeTopic.value === topic) {
        return;
      }
      emit('changeTopic', topic);
    };

    return {
      isDesktop,
      getNavGroupIconByCategory,
      getNavGroupInfoByCategory,
      handleOnCategoryChange,
      handleOnTopicChange
    };
  }
});
