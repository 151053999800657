import Vue from 'vue';
import Vuex from 'vuex';
import layout from '@/store/modules/layout/LayoutStore';

Vue.use(Vuex);

const store = new Vuex.Store<any>({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    layout
  }
});

export const useStore = () => store;

export default store;
