import state from './LayoutState';
import actions from './LayoutActions';
import mutations from './LayoutMutations';
import getters from './LayoutGetters';

// Namespaced Vuex module which contains its
// own state, actions, getters, mutations.
export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true
};
