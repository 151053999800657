import type { IMessage } from '@stomp/stompjs';

/**
 * Parse message received from sockets.
 * @param message Event message.
 * @param message.body Message body.
 * @returns Parsed message body.
 */
export const parseMessage = <T = any>({ body }: IMessage): T => {
  try {
    return JSON.parse(body);
  } catch (err) {
    return null;
  }
};

/**
 * Parse message received from sockets and add callback
 * @param message Event message.
 * @param message.body Message body.
 * @param handler Callback function
 * @returns Parsed message body.
 */
const parse = ({ body }: IMessage, handler: (data: any) => void): void => {
  if (typeof body === 'string') {
    try {
      handler(JSON.parse(body));
    } catch (err) {
      console.warn('Could not parse JSON string: ', body);
    }
  }
};

export default parse;
