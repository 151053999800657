import type { IMessage } from '@stomp/stompjs';
import { Client } from '@stomp/stompjs';
import { isAuthorized } from '@/helpers/tokenHelpers';
import { buildPrivateUrl, buildPublicUrl } from '@/sockets/helpers/buildPublicUrl';
import { parseMessage } from '@/sockets/helpers/parse';
import { EPushType, pushMessage } from '@/sockets/helpers/common';

import store from '@/store';
import { topics, EWSTopicType } from '@/sockets/topics';

export type TSubscribe = {
  topic: string;
  handler?: (data: any) => any;
  action?: string;
  featureFlag?: string | string[];
};

export const makeSubscribe =
  (client: Client, isPrivate: boolean | string) =>
  (subscription: TSubscribe): void => {
    const { topic, action, featureFlag, handler } = subscription;

    let featureFlags: string[] = [];

    if (typeof featureFlag === 'string') {
      featureFlags.push(featureFlag);
    } else if (Array.isArray(featureFlag)) {
      featureFlags = [...featureFlag];
    }

    const canAccess = !isPrivate || isAuthorized();
    const isEnabled =
      featureFlags.length === 0 || featureFlags.some((featureFlag) => store.state.layout[featureFlag] === true);

    if (!canAccess || !isEnabled) return;

    const isSeveral = isPrivate === 'several';
    const topicUrl = isSeveral ? topic : (isPrivate ? buildPrivateUrl : buildPublicUrl)(topic);

    client.subscribe(topicUrl, (msg: IMessage) => {
      try {
        const payload = parseMessage(msg);

        action ? store.dispatch(action, payload) : handler(payload);
      } catch (e) {
        console.warn('Could not parse JSON string: ', msg);
      }
    });

    pushMessage(topicUrl, EPushType.DEBUG, 'SUBSCRIBE');
  };

export const initSubscribes = (client: Client): void => {
  Object.entries(topics).forEach(([key, val]) => {
    val.forEach(makeSubscribe(client, key === EWSTopicType.PRIVATE));
  });
};
