export const FAQ_CATEGORIES = {
  LOYALTY: 'loyalty',
  COPY_STAKE: 'copyStake'
} as const;

export type FaqCategoriesType = typeof FAQ_CATEGORIES[keyof typeof FAQ_CATEGORIES];

export type FaqQuestion = {
  title: string;
  content: string;
};

export type FaqQuestions = {
  [key: string]: FaqQuestion;
};

export type FaqGroup = {
  title: string;
  description: string;
  navTitle: string;
  questions: FaqQuestions;
};

export type FaqByCategories = {
  [key in FaqCategoriesType]: FaqGroup;
};

export type FaqTopicNavigation = {
  prev?: string;
  next?: string;
};
