import { isSocketsDebugEnabled, isDebugEnabled } from '@/api/helpers/urlParams';
import trim from 'lodash/trim';

const LOG_TAG = 'WS';
const SERVER_NAME = trim(process.env.VUE_APP_API_URL.replace('https', 'wss'), '/');

export enum EPushType {
  DEBUG = 'DEBUG',
  ERROR = 'ERROR',
  WARN = 'WARN'
}

const METHOD: Record<EPushType, string> = {
  [EPushType.DEBUG]: 'info',
  [EPushType.ERROR]: 'error',
  [EPushType.WARN]: 'warn'
};

export const pushMessage = (msg: string, type: EPushType = EPushType.DEBUG, thread?: string): void => {
  try {
    if (!isSocketsDebugEnabled && !isDebugEnabled) return;

    if (typeof msg !== 'string') return;

    const message = msg?.startsWith('connected to server undefined') ? msg?.replace('undefined', SERVER_NAME) : msg;

    if (message) {
      console[METHOD[type]](`[${LOG_TAG}][${thread || type}]:`, message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const initReconnectListener = (handler: (isVisible: boolean) => void): void => {
  document.addEventListener('visibilitychange', () => handler(document.visibilityState === 'visible'), false);
};
