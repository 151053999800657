import { LayoutState, TSavedPreset } from './LayoutState';
import { i18n } from '@/plugins/i18n';
import type { UserInfo, WidgetTheme, TStreamer, TStreamSettings } from '@/api/schema';
import { getTokenLanguage, isAuthorized } from '@/helpers/tokenHelpers';
import type { TDisabledWidgetStatus } from '@/helpers/disabledWidgetStatusHelpers';
import { defaultLocale } from '@/helpers/localeHelpers';

/**
 * Mutations are the only way to actually change
 * state in the store and must be synchronous.
 * @category LayoutStore
 */
export default {
  setConfiguration(state: LayoutState, settings: TStreamSettings): void {
    state.maxBetLimit = settings?.maxBetVolumeActive ? settings.maxBetVolume : 0;
    state.showOnlyStreamers = settings?.showOnlyStreamers;
    state.streamUrl = settings?.streamUrl;
  },
  setStreamer(state: LayoutState, streamer: TStreamer): void {
    if (!streamer || state.operatorId !== streamer?.operatorId) return;

    state.streamer = streamer;
    state.streamer.playerWalletHash = state.walletHash;
  },

  updateSavedPreset(state: LayoutState, preset: Partial<TSavedPreset>): void {
    state.savedPreset = { ...state.savedPreset, ...preset };
  },

  updateStreamer(state: LayoutState, payload: Partial<TStreamer>): void {
    if (state.streamer) {
      state.streamer = { ...state.streamer, ...payload };
    }
  },

  setUserNickname(state: LayoutState, nickname: string): void {
    state.nickname = nickname;
  },
  /**
   * Set user login status.
   * @param state Layout state.
   * @param userData user data.
   */
  setUserInfo(state: LayoutState, userData: UserInfo): void {
    const {
      walletHash,
      operatorName,
      operatorId: currentOperatorId,
      language,
      operatorHomePageURL,
      nickname
    } = userData;
    state.nickname = nickname;
    state.userLogin = isAuthorized();
    state.walletHash = walletHash || null;
    state.operatorId = currentOperatorId || 1;

    state.lang = getTokenLanguage() || (typeof language === 'string' ? language.toLowerCase() : defaultLocale);

    state.operatorHomePageURL = operatorHomePageURL || null;

    const name = operatorName?.toLowerCase()?.startsWith('fairspin') ? 'Fairspin' : operatorName;
    state.operatorName = name || 'Casino';
  },

  /**
   * Set active language.
   * @param state Layout state.
   * @param data available languages.
   */
  setPermittedLangs(state: LayoutState, data: string[]): void {
    state.permittedLangs = data;
  },

  /**
   * Add loaded language.
   * @param state Layout state.
   * @param lang active language.
   */
  addLoadedLanguage(state: LayoutState, lang: string): void {
    state.loadedLanguages = [...state.loadedLanguages, lang];
  },

  /**
   * Set active language.
   * @param state Layout state.
   * @param lang active language.
   */
  setActiveLanguage(state: LayoutState, lang: string): void {
    state.lang = lang;
    i18n.locale = lang;
  },

  /**
   * Set theme.
   * @param state Layout state.
   * @param theme layout theme.
   */
  setTheme(state: LayoutState, theme: string | WidgetTheme): void {
    if (typeof theme == 'string') {
      state.theme = theme;
      state.themeColors = {};
    } else {
      state.theme = theme.value || state.theme;
      state.themeColors = theme.colors || {};
    }
  },

  /**
   * Toggle widget status
   * @param state Layout state
   * @param status status widget
   */

  setDisabledWidgetStatus(state: LayoutState, status: TDisabledWidgetStatus): void {
    state.disabledWidgetStatus = status;
  }
};
