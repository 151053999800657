import copyStake from '@/sockets/topics/private/copy-stake';
import operatorCopyStake from '@/sockets/topics/public/operator-copy-stake';

export enum EWSTopicType {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export const topics = Object.freeze({
  [EWSTopicType.PUBLIC]: [operatorCopyStake],
  [EWSTopicType.PRIVATE]: [copyStake]
});

export default topics;
