import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { defaultLocale } from '@/helpers/localeHelpers';
import { getTokenLanguage } from '@/helpers/tokenHelpers';

Vue.use(VueI18n);
const locale = getTokenLanguage() || defaultLocale;

export const i18n: VueI18n = new VueI18n({
  locale,
  fallbackLocale: locale,
  silentTranslationWarn: true
});

export const useI18n = (): VueI18n => {
  return i18n;
};
