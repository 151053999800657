import { getOperatorId, getUserId } from '@/helpers/tokenHelpers';

export const buildPublicUrl = (topic: string): string => {
  const operatorId = getOperatorId();

  return `/copystake/operator/${operatorId}/${topic}`;
};

export const buildPrivateUrl = (topic: string): string => {
  const userId = getUserId();

  return `/copystake/user/${userId}/${topic}`;
};
