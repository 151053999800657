










import { defineComponent } from '@vue/composition-api';
import FaqGroupDefault from '@/modules/faq/components/FaqGroupDefault';
import StreamIntroduction from '@/modules/faq/components/FaqGroupCopyStakeInstruction';
import useScreen from '@/composables/useScreen';

export default defineComponent({
  name: 'FaqGroupCopyStake',
  components: {
    FaqGroupDefault,
    StreamIntroduction
  },
  setup() {
    const { isDesktop } = useScreen();

    return { isDesktop };
  }
});
