
































import Vue from 'vue';
import { defineComponent, getCurrentInstance, PropType } from '@vue/composition-api';
import FaqTopic from '@/modules/faq/components/FaqTopic';
import CloseIcon from '../../assets/images/close-icon.svg?inline';
import ChevronIcon from '../../assets/images/chevron-icon.svg?inline';
import { FaqQuestion, FaqTopicNavigation } from '@/modules/faq/types';

export default defineComponent({
  name: 'FaqTopicMobile',
  components: {
    CloseIcon,
    ChevronIcon,
    FaqTopic
  },
  props: {
    categoryTitle: {
      type: String,
      required: true
    },
    topicInfo: {
      type: Object as PropType<FaqQuestion>,
      required: true
    },
    topicNavigation: {
      type: Object as PropType<FaqTopicNavigation>,
      required: true
    }
  },
  emits: ['close', 'changeTopic'],
  setup(props, { emit }) {
    const currentInstance = getCurrentInstance();
    const modal = currentInstance.proxy.$modal as Vue['$modal'];

    const handlerOnTopicChange = (payload?: string): void => {
      if (!payload) {
        return;
      }

      emit('changeTopic', payload);
    };
    const handleCloseModal = () => {
      emit('close');
    };
    const handleCloseFaqModal = () => {
      modal.hide('faq-modal');
    };

    return {
      handlerOnTopicChange,
      handleCloseModal,
      handleCloseFaqModal
    };
  }
});
