







import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'FaqTopic',
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      default: ''
    }
  }
});
