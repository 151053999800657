import type { TSubscribe } from '../../helpers/initSubscribes';
import { StreamEventBus } from '@/helpers/copyStakeHelpers';

/**
 * Subscribe to event which notifies about the new updates in stream.
 */
export default {
  topic: 'events',
  handler: ({ type, data }: any): void => {
    StreamEventBus.$emit(type, data);
  }
} as TSubscribe;
